<template>
  <div>
    <BannerVideo :video="video"></BannerVideo>

    <Details :video="video" />

    <div class="mt-4"></div>

    <div v-show="loading">
      <SkeletonLoader />
    </div>
    <div>
      <Items v-for="(category, key) in categories" :category="category" :categoryID="key" :key="key" />
    </div>
  </div>
</template>




<script>
  import {
    core
  } from '../../config/pluginInit'
  import Details from './MovieDetailPage/Detail'
  import BannerVideo from './MovieDetailPage/BannerVideo'
  import Items from './Components/ListItems/ListItems'
  import SkeletonLoader from './Components/ListItems/ItemSkeleton'
  import ApiService from '../../services/api'
  import AlertServie from '../../services/errors'
  export default {
    name: 'MovieDetail',
    components: {
      Details,
      Items,
      BannerVideo,
      SkeletonLoader
    },
    data() {
      return {
        video: {},
        categories: [],
        ContentID: this.$route.params.contentID,
        loading: true,
      }
    },
    methods: {
      openContent() {
        ApiService.openContent(this.$route.params.contentID).then((response) => {
          this.$store.commit('setPoints', response.data.user_points)
          this.video = response.data.data
        })
      },
      async getContentCategories() {
        await ApiService.getLastContentCategories().then(async (response) => {
          this.categories = response.data.data
        })
      },
      aletwithLang() {
        if (this.$i18n.locale === 'en') {
          AlertServie.info("to rewatch this content go to \"My Contents\"")
        } else if (this.$i18n.locale === 'ar') {
          AlertServie.info("لإعادة مشاهدة هذا المحتوى انتقل إلى (محتوياتي)")
        } else {
          AlertServie.info("بۆ دووبارە سەیرکردنی ئەم ناوەڕۆکە بڕۆ بۆ (لیستەکەم)")
        }
      }
    },
    mounted() {
      core.index()
      this.openContent()
      setTimeout(() => {
        this.getContentCategories()
      }, 300)
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

    beforeRouteUpdate(to, from, next) {
      if (to.params.contentID !== from.params.contentID && from.name !== 'landing-page') {
        this.aletwithLang()
      }
      next()
    }
  }
</script>